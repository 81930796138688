import 'firebase/database';

import firebase from 'firebase/app';

export const db = firebase
    .initializeApp({
        apiKey: 'AIzaSyD8o-uecBMClQmxGwU9avG9F8l66g4WBts',
        authDomain: 'shoppies-44d8b.firebaseapp.com',
        databaseURL:
            'https://shoppies-44d8b-default-rtdb.europe-west1.firebasedatabase.app',
        projectId: 'shoppies-44d8b',
        storageBucket: 'shoppies-44d8b.appspot.com',
        messagingSenderId: '800221896495',
        appId: '1:800221896495:web:73d4dcea4d95b66ecd4bda',
    })
    .database();

<template>
  <section id="shopping">
      <h1><strong>Shoppies</strong></h1>
      <ul class="favourites" >
        <li v-if="!favourites.length" class="dummy">none</li>
        <transition-group name="favlist">
          <li v-for="(item, index) in favourites" :key="item.name" @click.self="addFavouriteToList(item)">
            {{ item.name }} <button @click="removeFavourite(index)">✕</button>
          </li>
        </transition-group>       
      </ul>
      <div class="row">
            <div v-for="(item, index) in items" :key="item['.key']" class="item">
                <p>{{ item.name }}</p>
                <button class="add-fav">
                <svg v-if="checkIfFavourite(item)" @click="removeItemFromFavourites(item)" class="isfav" height="25" viewBox="0 0 30 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.79392 13.1058L3.78344 13.0944L3.77249 13.0834C2.42678 11.7315 1.6875 9.94076 1.6875 8.03312C1.6875 6.11893 2.43123 4.32352 3.78491 2.97013C6.57739 0.178777 11.1195 0.178885 13.9109 2.97002C14.0896 3.1487 14.259 3.33651 14.4173 3.5319L15 4.25089L15.5827 3.5319C15.7412 3.33629 15.9107 3.14809 16.0893 2.96914C18.8808 0.178287 23.4236 0.17885 26.215 2.96971C27.5684 4.32315 28.3125 6.11897 28.3125 8.03312C28.3125 9.94694 27.5685 11.7424 26.215 13.0956L26.2034 13.1072L26.1923 13.1193C21.0436 18.7399 18.2286 21.5266 16.6617 22.9042C15.8745 23.5963 15.4318 23.9062 15.187 24.0429C15.0829 24.101 15.0302 24.1189 15.0149 24.1234C15.0116 24.1234 15.0068 24.1234 15 24.1234C14.9933 24.1234 14.9885 24.1234 14.9851 24.1234C14.9697 24.1188 14.917 24.1009 14.8132 24.0429C14.5683 23.9061 14.1253 23.5959 13.3374 22.903C11.7689 21.5237 8.95042 18.7336 3.79392 13.1058Z" stroke="#F17A4F" stroke-width="2"/>
                </svg>
                <svg v-else @click="addToFavourites(item)" width="30" height="25" viewBox="0 0 30 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.79392 13.1058L3.78344 13.0944L3.77249 13.0834C2.42678 11.7315 1.6875 9.94076 1.6875 8.03312C1.6875 6.11893 2.43123 4.32352 3.78491 2.97013C6.57739 0.178777 11.1195 0.178885 13.9109 2.97002C14.0896 3.1487 14.259 3.33651 14.4173 3.5319L15 4.25089L15.5827 3.5319C15.7412 3.33629 15.9107 3.14809 16.0893 2.96914C18.8808 0.178287 23.4236 0.17885 26.215 2.96971C27.5684 4.32315 28.3125 6.11897 28.3125 8.03312C28.3125 9.94694 27.5685 11.7424 26.215 13.0956L26.2034 13.1072L26.1923 13.1193C21.0436 18.7399 18.2286 21.5266 16.6617 22.9042C15.8745 23.5963 15.4318 23.9062 15.187 24.0429C15.0829 24.101 15.0302 24.1189 15.0149 24.1234C15.0116 24.1234 15.0068 24.1234 15 24.1234C14.9933 24.1234 14.9885 24.1234 14.9851 24.1234C14.9697 24.1188 14.917 24.1009 14.8132 24.0429C14.5683 23.9061 14.1253 23.5959 13.3374 22.903C11.7689 21.5237 8.95042 18.7336 3.79392 13.1058Z" stroke="#F17A4F" stroke-width="2"/>
                </svg>
                </button>
                <button @click="removeItem(index)">✕</button>
            </div>
      </div>
      <input type="text" placeholder="Item Name..." @keyup.enter="addItem" v-model="inputVal" />
    </section>
</template>

<script>

import {db} from './db';

export default {
  name: 'App',
  data() {
    return {
      inputVal: "",
      favourites: [
        { name: "Smeerkaas" }, 
        { name: "Kip" }
      ],
      items: [
        { name: "Siracha" }, 
        { name: "Kip" }
      ],
    };
  },
  methods: {
    addItem(event) {
      if(event.target.value === '') {
        return
      }

      db.ref('items').push({name: this.inputVal});

      this.inputVal = "";
    },
    removeItem(index) {
        db.ref('items/' + this.items[index]['.key']).remove();
    },
    removeFavourite(index) {
        db.ref('favourites/' + this.favourites[index]['.key']).remove();
    },
    removeItemFromFavourites(item) {
        const index = this.favourites.findIndex(favourite => item.name === favourite.name);

         db.ref('favourites/' + this.favourites[index]['.key']).remove();
    },
    addFavouriteToList(item) {
      db.ref('items').push({name: item.name});
    },
    checkIfFavourite(item) {
      return this.favourites.some(favourite => item.name === favourite.name);
    },
    addToFavourites(item) {
        db.ref('favourites').push({name: item.name});
    },

  },
  firebase: {
      favourites: db.ref('favourites'),
      items: db.ref('items')
  }
}
</script>

<style>
@font-face {
    font-family: 'NeueHaasGroteskDisp Pro';
    src: url('assets/fonts/NHaasGroteskDSPro-55Rg.eot');
    src: url('assets/fonts/NHaasGroteskDSPro-55Rg.eot?#iefix')
        format('embedded-opentype'),
        url('assets/fonts/NHaasGroteskDSPro-55Rg.woff2') format('woff2'),
        url('assets/fonts/NHaasGroteskDSPro-55Rg.woff') format('woff'),
        url('assets/fonts/NHaasGroteskDSPro-55Rg.ttf') format('truetype'),
        url('assets/fonts/NHaasGroteskDSPro-55Rg.svg#NHaasGroteskDSPro-55Rg')
        format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NeueHaasGroteskDisp Pro';
    src: url('assets/fonts/NHaasGroteskDSPro-75Bd.eot');
    src: url('assets/fonts/NHaasGroteskDSPro-75Bd.eot?#iefix')
        format('embedded-opentype'),
        url('assets/fonts/NHaasGroteskDSPro-75Bd.woff2') format('woff2'),
        url('assets/fonts/NHaasGroteskDSPro-75Bd.woff') format('woff'),
        url('assets/fonts/NHaasGroteskDSPro-75Bd.ttf') format('truetype'),
        url('assets/fonts/NHaasGroteskDSPro-75Bd.svg#NHaasGroteskDSPro-75Bd')
        format('svg');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NeueHaasGroteskDisp Pro';
    src: url('assets/fonts/NHaasGroteskDSPro-45Lt.eot');
    src: url('assets/fonts/NHaasGroteskDSPro-45Lt.eot?#iefix')
        format('embedded-opentype'),
        url('assets/fonts/NHaasGroteskDSPro-45Lt.woff2') format('woff2'),
        url('assets/fonts/NHaasGroteskDSPro-45Lt.woff') format('woff'),
        url('assets/fonts/NHaasGroteskDSPro-45Lt.ttf') format('truetype'),
        url('assets/fonts/NHaasGroteskDSPro-45Lt.svg#NHaasGroteskDSPro-45Lt')
        format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NeueHaasGroteskDisp Pro';
    src: url('assets/fonts/NHaasGroteskDSPro-65Md.eot');
    src: url('assets/fonts/NHaasGroteskDSPro-65Md.eot?#iefix')
        format('embedded-opentype'),
        url('assets/fonts/NHaasGroteskDSPro-65Md.woff2') format('woff2'),
        url('assets/fonts/NHaasGroteskDSPro-65Md.woff') format('woff'),
        url('assets/fonts/NHaasGroteskDSPro-65Md.ttf') format('truetype'),
        url('assets/fonts/NHaasGroteskDSPro-65Md.svg#NHaasGroteskDSPro-65Md')
        format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

body {
  margin: 0;
  padding: 0;
  background: #23113F;
  font-family: "Helveita Neue", helvetica, arial, sans-serif;
}
#shopping {
  max-width: 640px;
  margin: auto;
  padding: 0 5%;
}
h1 {
  margin: 50px 0 50px;
  font-size: 40px;
  font-weight: 800;
  font-family: 'NeueHaasGroteskDisp Pro', 'Helveita Neue', helvetica, arial, sans-serif;
  color: white;
  text-transform: uppercase;
  font-style: italic;
}
ul.favourites {
  margin: 0 0 30px;
  padding: 0;
}
ul.favourites li {
  position: relative;
  border: 2px solid #FF17CE;
  color: #FF17CE;
  /* background: #f37a48; */
  display: inline-block;
  margin: 0 3px 7px;
  padding: 6px 35px 5px 12px;
  border-radius: 18px;
  cursor: pointer;
  transition: all 80ms linear;
  text-transform: capitalize;
  line-height: 20px !important;
}
ul.favourites li:first-child {
  margin-left: 0;
}
ul.favourites li:hover {
  background: #FF17CE;
  color: white;
}
ul.favourites button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px 10px 8px 6px;
  color: #FF17CE;
  font-weight: bold;
  font-size: 14px;
  border: none;
  background: transparent;
  cursor: pointer;
}
ul.favourites li:hover button {
    color: black;
}
ul.favourites button:hover {
  color: white !important;
}
ul.list {
  margin: 0;
  padding: 20px 0;
}
ul.list li {
  display: inline-block;
  width: 100%;
  margin: 5px;
}
div.item {
  position: relative;
  text-transform: capitalize;
  background: transparent;
  color: #FF17CE;
  border: 1px solid #FF17CE;
  border-bottom: none;
}    
div.item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 0;
}
div.item:first-child button {
  border-top-right-radius: 0;
}
div.item:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 0;
  border-bottom: 1px solid #FF17CE;
}
div.item:last-child button {
  border-bottom-right-radius: 0;
}

div.item p {
  display: inline-block;
  margin: 14px 18px 12px;
  font-size: 18px;
  width: calc(100% - 125px);
}
.item button {
  position: absolute;
  width: 48px;
  height: 100%;
  cursor: pointer;
  font-weight: 700;
  font-size: 21px;
  display: inline-block;
  background: transparent;
  color: #FF17CE;
  border: none;
  top: 0;
  right: 0;
  /* border-top-right-radius: 8px;
  border-bottom-right-radius: 8px; */
}
.item button:hover {
  color: white;
}
*,
button:focus {
  outline:0;
  -moz-appearance: none;
  -webkit-appearance: none;  
  appearance: none;
}
button.add-fav {
  right: 44px;
  background: transparent;
  color: #FF17CE;
  font-size: 28px;
}
input {
  width: 100%;
  padding: 16px;
  margin-top: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: none;
  background: #3A235A;
  color: white;
  font-size: 16px;
  outline: none;
  border-radius: 4px;
}
input::placeholder {
  color: #888;
}
.label {
  margin: 30px 0 6px;
  font-size: 13px;
  font-style: italic;
  color: #777;
}
.fav {
  margin: 11px 10px 5px 0;
  float: left;
}
svg {
  width: 24px;
  margin-top: 25%;
  /* fill: #f37a48; */
}
svg path {
    stroke: #FF17CE;
}
svg.isfav {
  fill: #FF17CE;
  stroke: none;
}
svg:hover {
  fill: white;
}
/* svg.isfav:hover {
  fill: transparent;
} */
svg:hover path {
  stroke: white;
  stroke-width: 3;
}

.itemlist-enter-active {
  transition: all 200ms linear;
}
.itemlist-leave-active,
.favlist-enter-active,
.favlist-leave-active {
  transition: all 50ms linear;
}
.itemlist-enter-from,
.itemlist-leave-to,
.favlist-enter-from,
.favlist-leave-to {
  opacity: 0;
}


</style>
